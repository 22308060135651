import React, {useEffect} from 'react'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'
import styled from 'styled-components'
import media from 'styled-media-query'
import DiscountablePlanCard from './components/DiscountablePlanCard'
import {DiscountCodeValidationResult} from './types/Purchasing'
import {useHistory} from 'react-router-dom'


const BlackFridayPricing: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageBlackFridayPricing);
    window.scrollTo(0, 0);
  }, []);

  const onPlanCardClick = () => {
    console.log('click happened!')
    history.push('/signin')
  }

  return (
    <ContentContainer>
      <StyledTitle>Black Friday 2024</StyledTitle>
      <StyledDiv>
        <StyledHeading>
          Promo Code: BF2024
        </StyledHeading>
        <StyledText>
          The following discounts are available until <b>Friday, December 6</b>:
        </StyledText>
        <Subheading>
          Discounts on Yearly Plans
        </Subheading>
        <StyledPlansRow onClick={onPlanCardClick}>
          <StyledDiscountablePlanCard selectedPlan={'GOLD_YEARLY'} discountCodeValidationResult={DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH}/>
          <StyledDiscountablePlanCard selectedPlan={'PLATINUM_YEARLY'} discountCodeValidationResult={DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH}/>
        </StyledPlansRow>
        <Subheading>
          Discounts on Monthly Plans
        </Subheading>
        <StyledPlansRow onClick={onPlanCardClick}>
          <StyledDiscountablePlanCard selectedPlan={'BASIC'} discountCodeValidationResult={DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS}/>
          <StyledDiscountablePlanCard selectedPlan={'GOLD'} discountCodeValidationResult={DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS}/>
          <StyledDiscountablePlanCard selectedPlan={'PLATINUM'} discountCodeValidationResult={DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS}/>
        </StyledPlansRow>
        <StyledText>
          Be sure to use the promo code <b>BF2024</b> at checkout.
        </StyledText>
      </StyledDiv>
    </ContentContainer>
  )
}

const StyledPlansRow = styled.div<{$isDisabled?: boolean}>`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    ${media.greaterThan("medium")`
        flex-direction: row;
        justify-content: space-evenly;
        gap: 24px;
    `}
    opacity: ${({ $isDisabled }) => $isDisabled ? 0.5 : 1};
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #faf6e8;

`
const StyledTitle = styled.h1`
    margin: 24px 0px 9px 0;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    line-height: 1.25em;

    text-align: center;

    text-transform: uppercase;
    font-size: 30px !important;
    ${media.greaterThan("medium")`
      font-size: 2.5em !important;
    `}
`;

const StyledDiv = styled.div`
   margin-top: 24px;
   max-width: 300px;
   ${media.greaterThan("medium")`
    max-width: 1050px;
  `}
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.black};
  line-height: 1.25em;
  text-align: center;
`;

const Subheading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.2em;
  color: ${props => props.theme.colors.black};
  line-height: 1.25em;
  text-align: center;
`;

const StyledText = styled.div`
  margin: 0 0 18px 0;
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 1em;
  }
`;

const StyledDiscountablePlanCard = styled(DiscountablePlanCard)`
    cursor: pointer;
`

export default BlackFridayPricing
