import React from 'react';
import styled from "styled-components";
import {BaseSCUser} from './types/SoundCloudUsers';
import { FaRegTrashAlt } from "react-icons/fa";


type SCUserListItemProps = {
    user: BaseSCUser,
    isDeletable?: boolean,
    onDeleteClick?: (userId: string) => void
};

const StyledPhotoLink = styled.a`
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    img {
        width: 100%;
        height: 100%;
    }
`;

const StyledUsernameLink = styled.a`
    text-decoration: none;
    color: black;
`;

const StyledPhotoContainer = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 15px;
    flex-basis: 30px;
    background-color: ${props => props.theme.colors.yellow};
    border-radius: 50%;
`;

const StyledUserRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledCell = styled.div`
    padding: 8px 16px;
`;

const StyledSpacerContianer = styled.div`
    flex-basis: 30px;
`;

const StyledDeleteButtonContainer = styled.div`
    flex-basis: 30px;
    cursor: pointer;
`;

const SCUserListItem: React.FC<SCUserListItemProps> = ({ user, isDeletable = false, onDeleteClick } ) => {
    return (
        <StyledCell>
            <StyledUserRow>
                <StyledPhotoContainer>
                    <StyledPhotoLink href={`https://soundcloud.com/${user.username}`} target="_blank">
                        <img src={user.photoURL} alt=''/>
                    </StyledPhotoLink>
                </StyledPhotoContainer>
                <div>
                    <StyledUsernameLink href={`https://soundcloud.com/${user.username}`} target="_blank">
                        <span>{user.username}</span>
                    </StyledUsernameLink>
                </div>
                { isDeletable ?
                  <StyledDeleteButtonContainer>
                      <FaRegTrashAlt onClick={onDeleteClick?.bind(null, user.id)} />
                  </StyledDeleteButtonContainer>
                 :
                  <StyledSpacerContianer/>
                }
            </StyledUserRow>
        </StyledCell>
    );
};

export default SCUserListItem;
