import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import media from "styled-media-query";
import Modal from 'react-modal';
import './App.css';
import AppHeader from "./AppHeader";
import GlobalStyle from "./GlobalStyle";
import PasswordEditForm from "./PasswordEditForm";
import PasswordNewForm from "./PasswordNewForm";
import EditSubscriptionPage from './EditSubscriptionPage';
import StemMasteringWorkshopForm from './StemMasteringWorkshopForm'
import StemMasteringWorkshopReplay from './StemMasteringWorkshopReplay'
import ArtistEligibilityForm from './ArtistEligibilityForm'
import Root from "./Root";
import Dashboard from './Dashboard';
import SigninForm from "./SigninForm";
import Signup from "./SignupForm";
import NotFound from "./NotFound";
import HowItWorks from './HowItWorks';
import Privacy from './Privacy';
import Terms from './Terms';
import StyledLink from "./StyledLink";
import Theme from "./theme";
import { RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { verifyAuth } from './actions';
import AuthedWrapper from './AuthedWrapper';
import CancellationFlow from './CancellationFlow';
import * as keys from './common/keys';
import SoundCloudUsersModal from './SoundCloudUsersModal';
import SupportContactInfo from './SupportContactInfo';
import Button from './Button';
import FlashContainer from './FlashContainer';
import Callback from './Callback';
import InfluencerPayoutPolicy from './InfluencerPayoutPolicy'
import AdminLoginForm from './AdminLoginForm'
import Referrals from './Referrals'
import PromoterPayoutPolicy from './PromoterPayoutPolicy'
import BlackFridayPricing from './BlackFridayPricing'

Modal.setAppElement('#root');

const StyledFooter = styled.footer`
  padding: 2em 0em;
  background-color: ${props => props.theme.colors.yellow};
`;

const FooterContent = styled.div`
  width: 82%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: start;

  ${media.greaterThan("medium")`
    flex-direction: row;
    text-align: left;
  `}
`;

const FooterLinks = styled.div`
  flex-basis: 28%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 1.5em 0;
  justify-content: center;
  text-align: center;

  ${StyledLink} {
    display: block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 1em;

    :nth-child(4) {
      margin-bottom: 20px;
    }
  }
  ${media.greaterThan("medium")`
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: left;

`}
`;

const FooterEthos = styled.div`
  flex-basis: 69%;
  color: ${props => props.theme.colors.white};
  p {
    margin: 0 0 0.8125em;
  }
`;

const StyledConfirmationModalForm = styled.form`
    height: calc(100% - 0.5em);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

const StyledConfirmationText = styled.div`
    margin: 1.5em;
    text-align: center;
`;

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  width: 300px;
  align-self: center;
`;

const StyledPasswordField = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 80%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  background-color: white;
  min-width: 0px;
`;

type AppProps = {
  verifyAuth: typeof verifyAuth,
}

const App: React.FC<AppProps> = ({ verifyAuth }) => {

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  const [privateAccessPassword, setPrivateAccessPassword] = useState('');

  const handlePrivateAccessPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPrivateAccessPassword(evt.target.value);
  };

  const handlePrivateAccessPasswordFormSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    window.sessionStorage.setItem(keys.PrivateAccessPasswordKey, privateAccessPassword);
    window.location.reload();
  };


  if (keys.FeatureFlagIsPrivateAccess) {
    const storedKey = window.sessionStorage.getItem(keys.PrivateAccessPasswordKey);
    if (!storedKey || storedKey !== keys.PrivateAccessPassword) {
      return (
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <SoundCloudUsersModal isModalOpen={true} closeModal={() => {}} title='Private Access Only'>
            <StyledConfirmationModalForm onSubmit={handlePrivateAccessPasswordFormSubmit}>
              <StyledConfirmationText>
                Artist Management is not available to the public at this time. If you have been given a private
                access password, you may enter it here.
              </StyledConfirmationText>
              <StyledPasswordField
                  type="password"
                  value={privateAccessPassword}
                  onChange={handlePrivateAccessPasswordChange}
                  placeholder="Password"
              />
              <StyledButton>Submit</StyledButton>
            </StyledConfirmationModalForm>
          </SoundCloudUsersModal>
        </ThemeProvider>
      );
    }
  }

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <BrowserRouter>
        <AppHeader/>
        <FlashContainer/>
        <Switch>
          <Route exact path="/">
            <Root />
          </Route>

          <Route path="/how-it-works">
              <HowItWorks />
          </Route>

          <Route exact path="/subscription/edit">
            <AuthedWrapper>
              <EditSubscriptionPage/>
            </AuthedWrapper>
          </Route>

          <Route exact path="/subscription/cancel">
            <AuthedWrapper>
              <CancellationFlow/>
            </AuthedWrapper>
          </Route>

					<Route path="/auth/soundcloud/callback">
						<Callback />
					</Route>

          <Route exact path="/dashboard">
            <Dashboard/>
          </Route>

          <Route exact path="/dashboard/unfollowers">
            <Dashboard isBetrayersModalOpen={true}/>
          </Route>

          <Route exact path='/dashboard/one-way-follows'>
            <Dashboard isOneWayFollowsModalOpen={true}/>
          </Route>

          <Route exact path="/dashboard/scheduled-follows">
            <Dashboard isToFollowUsersModalOpen={true}/>
          </Route>

          <Route exact path="/dashboard/follow-backs">
            <Dashboard isFollowBackUsersModalOpen={true}/>
          </Route>

          <Route exact path="/dashboard/pending-follow-backs">
            <Dashboard isNonFollowBackUsersModalOpen={true}/>
          </Route>

          <Route exact path="/influencer-payouts">
            <InfluencerPayoutPolicy/>
          </Route>

          <Route exact path="/promoter-payouts">
            <PromoterPayoutPolicy/>
          </Route>

          <Route exact path="/referrals">
            <Referrals />
          </Route>

          <Route exact path="/privacy">
            <Privacy />
          </Route>

          <Route exact path="/terms">
            <Terms />
          </Route>

          <Route exact path="/signin">
            <SigninForm />
          </Route>

          <Route exact path="/signup">
            <Signup />
          </Route>

          <Route exact path="/users/password/new">
            <PasswordNewForm />
          </Route>

          <Route exact path="/users/password/edit/:token">
            <PasswordEditForm />
          </Route>

          <Route exact path="/stem-mastering">
            <StemMasteringWorkshopForm />
          </Route>

          <Route exact path="/stem-mastering/thank-you">
            <ArtistEligibilityForm />
          </Route>

          <Route exact path="/stem-mastering/:token">
            <StemMasteringWorkshopReplay />
          </Route>

          <Route exact path="/admin/login">
            <AdminLoginForm/>
          </Route>

          <Route exact path='/black-friday-2024'>
            <BlackFridayPricing/>
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>

        <StyledFooter>
          <FooterContent>
            <FooterLinks>
              <StyledLink to={"/terms"}>Terms of Service</StyledLink>
              <StyledLink to={"/privacy"}>Privacy Policy</StyledLink>
            </FooterLinks>

            <FooterEthos>
              <p>
                Artist Management is all about letting real people naturally discover your music. We employ
                proprietary algorithms to expose your music to listeners who are most likely to appreciate it.
              </p>

              <p>
                We do our best to create the right conditions for these listeners to discover and engage with your music.
                For best results, your music does need to sound reasonably professional.
              </p>
              <SupportContactInfo/>
            </FooterEthos>
          </FooterContent>
        </StyledFooter>
      </BrowserRouter>
    </ThemeProvider>
  );
};

function mapStateToProps(state: RootState) {
  return {
    errorMessage: state.flash.errorMessage,
    successMessage: state.flash.successMessage,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ verifyAuth }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(App);
